import * as React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';
import linkResolver from '../utils/linkResolver.js';
import Layout from '../components/layout';

const PreviewPage = ({ isPreview, isLoading, previewData }) => {
    if (isPreview === false) return 'Not a preview!';

    return (
        <Layout>{ isLoading ? <p>Loading...</p> : <p>Not Loading</p> }</Layout>
    );
};

export default withPreviewResolver(PreviewPage, {
    repositoryName: process.env.PRISMIC_REPOSITORY_NAME,
    linkResolver,
});
